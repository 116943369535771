import { OnInit, Component, Input } from "@angular/core";
import { ImageDisplayService } from 'app/_componentes/image-display/image-display.service';

@Component({ selector: 'fotos-pedido', templateUrl: './fotos-pedido.component.html', styleUrls: ['./fotos-pedido.component.scss'] })
export class SellerFotosPedidoComponent implements OnInit {
    public exibir_fotos: boolean = false;

    @Input() fotos: any;
    @Input() panel_aberto;

    constructor(private imageDisplayService: ImageDisplayService) {}

    ngOnInit() {
        this.exibir_fotos = this.panel_aberto;
    }

    zoomImage(imageSrc) {
        this.imageDisplayService.setImage(imageSrc);
    }
}
