import { Component, OnInit, Input } from '@angular/core';

@Component({ selector: 'app-condicao-fornecimento', templateUrl: './condicao-fornecimento.component.html', styleUrls: ['./condicao-fornecimento.component.scss'] })
export class SellerCondicaoFornecimentoComponent implements OnInit
{
    @Input() condicoes: any;

    active: number;
    formas: any;
    fretes: any;
    prazos: any;

    constructor()
    { }

    ngOnInit(): void
    {
        this.active = 1;
        this.formas = this.condicoes.formasPagamento;
        this.fretes = this.condicoes.fretes;
        this.prazos = this.condicoes.prazosEntrega;
    }
}
