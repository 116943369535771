<div class="feedback">
    <div class="fundo d-flex flex-wrap">
        <div class="img-container col">
            <div class="imagem {{imagem}}"></div>

            <div class="txt-container">
                <h3 class="titulo mb0">{{ titulo }}</h3>
                <br />
                <h4 class="subtitulo">{{ subtitulo }}</h4>
                @if (isInternalSeller) {
                    <button class="btn btn-light" (click)="irTelaVisaoGeral()">{{ botaoVoltar }}</button>
                }
            </div>
        </div>
    </div>
</div>
