<div class="titulo-pagina">
    <div>
        <div class="nome-veiculo truncate">
            <i class="float-start media-box-object rounded-circle marca {{responderCompService.answerQuotation?.brand}}"></i><span>{{responderCompService.answerQuotation?.vehicle_year}}</span>
        </div>
        <div class="dados-veiculo d-flex flex-wrap">
            <div class="linha"><div class="col-0-5"><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.chassi-menor' | translate}}:</b></div> <div class="col-2">{{responderCompService.answerQuotation.chassi}}</div></div>
            <div class="linha">
                <div class="col-0-5"><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.tipos-pecas-menor' | translate}}:</b></div>
                <div class="col-2 part-type">
                    @if (responderCompService.answerQuotation?.part_type_genuina) {
                        <span><i class="fa fa-wrench"></i> <span style="margin-right:8px">{{ 'elemento.tipo-pecas.genuina-2' | translate}}</span></span>
                    }
                    @if (responderCompService.answerQuotation?.part_type_original) {
                        <span><i class="fa fa-wrench"></i> <span style="margin-right:8px">{{ 'elemento.tipo-pecas.original-2' | translate}}</span></span>
                    }
                    @if (responderCompService.answerQuotation?.part_type_alternativa) {
                        <span><i class="fa fa-wrench"></i> <span style="margin-right:8px">{{ 'elemento.tipo-pecas.alternativa-2' | translate}}</span></span>
                    }
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 area-responder">
    <ng-scrollbar height="auto" #scrollable="ngScrollbar" class="steps-scrollable">
        @if (passo_atual === 1) {
            <div class="row passo-1">
                <div class="col-md-12">
                    <div class="card card-primary orcamento-solicitado">
                        <div class="card-header painelcabecalho">
                            {{ 'seller.dashboard.detalhes.informacoes.cabecalho.selecione-pecas' | translate}}
                        </div>
                        <div class="card-body font12">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th class="w_eighty_two"><b>{{ 'seller.dashboard.responder.passos.passo-1.cabecalho.tabela.peca-nome' | translate}}</b></th>
                                        <th class="w_eighteen align-center">
                                            {{ 'seller.dashboard.responder.passos.passo-1.cabecalho.tabela.quantidade' | translate}}
                                        </th>
                                        <th class="w_five">
                                            <div class="form-check c-checkbox needsclick checkboxcot">
                                                <label class="needsclick">
                                                    <input type="checkbox" class="needsclick todas_pecas" (click)="pecas_selecionar_todas($event);">
                                                    <span class="fa fa-check"></span>
                                                </label>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (peca of responderCompService.answerQuotation.pecas; track peca) {
                                        <tr>
                                            <td class="truncate-column"><span class="truncate">@if (peca.code) {
                                                <span>{{peca.code}} - </span>
                                            }({{peca.quantidade}}) - {{peca.name}}</span></td>
                                            <td>
                                                <input class="form-control" type="number" min="0" max="{{peca.quantidade}}" [(ngModel)]="peca.quantity_in_stock" [readonly]="peca.desabilitar_quantity_in_stock ? 'disabled' : null" (blur)="validateQuantityInStock(peca)" />
                                            </td>
                                            <td>
                                                <div class="form-check c-checkbox needsclick checkboxcot">
                                                    <label class="needsclick">
                                                        <input type="checkbox" class="needsclick peca_notificacao id_{{peca.item_id}}" (click)="pecas_selecionar_uma(peca, $event);">
                                                        <span class="fa fa-check"></span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="alerta-conferencia">
                        <span class="text-danger text-bold"><i class="fa fa-exclamation-triangle"></i> {{ 'seller.dashboard.detalhes.informacoes.cabecalho.alerta-conferencia' | translate}}</span>
                    </div>
                    <div class="card card-primary dados-oficina">
                        <div class="card-header painelcabecalho">
                            <b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.solicitado' | translate}}</b>
                            <span class="namecompr">{{responderCompService.answerQuotation.buyer_name}} ({{responderCompService.answerQuotation.repairer_name}})</span> <small><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.cod-orcamento' | translate}}:</b>    #{{responderCompService.answerQuotation.quotation_id}}</small>
                        </div>
                        <div class="card-body font12">
                            <div class="dados-cotacao col-md-12">
                                <div class="col-md-6">
                                    <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.cnpj' | translate}}:</b> {{responderCompService.answerQuotation.repairer_cnpj_formatado}}</span>
                                    <br />
                                    <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.telefone' | translate}}:</b> {{responderCompService.answerQuotation.buyer_phone_radio}}</span>
                                    <br />
                                    <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.endereco' | translate}}:</b> {{responderCompService.answerQuotation.repairer_address}}</span>
                                </div>
                                <div class="col-md-6">
                                    @if (responderCompService.answerQuotation?.observacoes_comprador?.length > 0) {
                                        <span><b>{{ 'seller.dashboard.detalhes.informacoes.cabecalho.observacao-comprador' | translate}}:</b> {{responderCompService.answerQuotation.observacoes_comprador}}</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    @if (responderCompService.answerQuotation?.fotos.length > 0) {
                        <div class="fotos-veiculo">
                            <div class="col-md-12 fotos">
                                <fotos-pedido [fotos]="responderCompService.answerQuotation?.fotos" [panel_aberto]="true"></fotos-pedido>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }

        @if (passo_atual > 1 && passo_atual < passo_final) {
            <div>
                @for (peca of pecas_respondidas_nopasso; track peca) {
                    <div class="row passo-2">
                        <div class="col-md-12">
                            <div class="card card-default peca-resposta">
                                <div class="card-header">
                                    <label class="truncate">
                                        @if (peca.code) {
                                            <span>{{peca.code}} - </span>
                                        }
                                        {{peca.name}}
                                    </label>
                                </div>
                                <div class="card-body d-flex flex-wrap">
                                    <div class="linha">
                                        <div class="mb-3 form-group col">
                                            <label class="col-md-12 col-form-label">{{'seller.dashboard.responder.passos.passo-2.campos.codigo' | translate}}</label>
                                            <div class="col-md-12">
                                                <div class="input-group input-group-sm">
                                                    <input type="text" placeholder="{{peca.code}}" [(ngModel)]="peca.new_part_code" maxlength="50" [readonly]="!peca.block_edit ? true : null" class="form-control form-control-sm" />
                                                    <span class="input-group-text form-control-sm cursor-pointer" (click)="peca.block_edit = !peca.block_edit">
                                                        <em class="fa fa-pencil" [ngClass]="peca.block_edit ? 'fa-save' : 'fa-pencil'"></em>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3 form-group col">
                                            <label class="col-md-12 col-form-label">{{'seller.dashboard.responder.passos.passo-2.campos.prazoentrega' | translate}} <i class="fa fa-info-circle" tooltip="{{ 'seller.dashboard.responder.tabela.prazo.tooltip' | translate}}"></i></label>
                                            <div class="col-md-12">
                                                <select class="form-select form-select-sm inputVend" [(ngModel)]="peca.delivery_time" (blur)="pecas_atualizar_validacao('valor', peca)">
                                                    @for (prazo of responderCompService.prazosEntrega; track prazo) {
                                                        <option [value]="prazo.delivery_time_id">{{prazo?.prazo_formatado_com_tipo}}</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="linha">
                                        <div class="mb-3 form-group col">
                                            <label class="col-md-12 col-form-label">{{'seller.dashboard.responder.passos.passo-2.campos.valor' | translate}}</label>
                                            <div class="col-md-12">
                                                <input class="form-control" type="text" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="99999.99" maxlength="9" [(ngModel)]="peca.valor_unitario" (blur)="pecas_atualizar_validacao('valor', peca)" />
                                            </div>
                                        </div>
                                        <div class="mb-3 form-group col">
                                            <label class="col-md-12 col-form-label">{{'seller.dashboard.responder.passos.passo-2.campos.desconto' | translate}}</label>
                                            <div class="col-md-12">
                                                <input class="form-control" type="text" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="99" maxlength="5" [(ngModel)]="peca.desconto" (blur)="pecas_atualizar_validacao('desconto', peca)" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="linha">
                                        <div class="mb-3 form-group col">
                                            <label class="col-md-12 col-form-label">{{'seller.dashboard.responder.passos.passo-2.campos.tipopeca' | translate}}</label>
                                            <div class="col-md-12">
                                                <select id="tipo_peca_0" class="form-select form-select-sm inputVend" (change)="pecas_atualizar_tipo_peca(peca)" [(ngModel)]="peca.part_type">
                                                    @if (responderCompService.part_types.genuina) {
                                                        <option value="1">{{ 'elemento.tipo-pecas.genuina-2' | translate}}</option>
                                                    }
                                                    @if (responderCompService.part_types.original) {
                                                        <option value="2">{{ 'elemento.tipo-pecas.original-2' | translate}}</option>
                                                    }
                                                    @if (responderCompService.part_types.alternativa) {
                                                        <option value="4">{{ 'elemento.tipo-pecas.alternativa-2' | translate}}</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div class="mb-3 form-group col">
                                            <label class="col-md-12 col-form-label">{{'seller.dashboard.responder.passos.passo-2.campos.marca' | translate}}</label>
                                            <div class="col-md-12">
                                                <input type="text" [readonly]="peca.desabilitar_marca ? 'disabled' : null" maxlength="30" class="form-control form-control-sm inputVend" [(ngModel)]="peca.brand" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="linha totalizador">
                                        <div class="mb-3 form-group col mb0">
                                            <div class="col-md-12">
                                                <label>{{'seller.dashboard.responder.passos.passo-2.campos.quantidade' | translate}}</label>: <span>{{peca.quantidade}}</span>
                                            </div>
                                            <div class="col-md-12">
                                                <label>{{'seller.dashboard.responder.passos.passo-2.campos.total' | translate}}</label>: <span>{{sellerService.obterTotalPeca(peca) | currency:'R$ '}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }

        @if (passo_atual === passo_final) {
            <div class="row passo-3">
                <div class="col-md-12">
                    <div class="card card-default condicoes-fornecimento">
                        <div class="card-header">
                            {{ 'seller.dashboard.detalhes.informacoes.cabecalho.condicoes-fornecimento' | translate}}
                        </div>
                        <div class="card-body d-flex flex-wrap">
                            @if (!responderCompService.condicoesFornecimento?.done_configuration || responderCompService.condicoesFornecimento?.fretes.length === 0) {
                                <div class="mb-3 form-group linha frete">
                                    <div class="col">
                                        <label class="linha col-form-label">{{ 'seller.dashboard.responder.frete-gratuito.label' | translate}}</label>
                                        <div class="col">
                                            <select name="frete" class="form-select form-select-sm m-b" [(ngModel)]="responderCompService.answerQuotation?.frete.tipo" (change)="responderCompService.atualizarFrete(responderCompService.answerQuotation?.frete.valor)">
                                                <option value="1">{{ 'elemento.generico-select.sim' | translate}}</option>
                                                <option value="2">{{ 'elemento.generico-select.nao' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col checkbox-group">
                                        @if (responderCompService.answerQuotation?.frete.tipo==='2') {
                                            <label class="linha col-form-label">{{ 'seller.dashboard.responder.frete-gratuito.valor' | translate}}</label>
                                        }
                                        <div class="col">
                                            @if (responderCompService.answerQuotation?.frete.tipo==='2') {
                                                <input type="text" id="frete_valor" [(ngModel)]="responderCompService.answerQuotation?.frete.valor" (change)="responderCompService.atualizarFrete()" [readonly]="(responderCompService.answerQuotation?.frete.nao_entrega || responderCompService.answerQuotation?.frete.conta_comprador) ? 'disabled' : null" placeholder="{{'seller.dashboard.responder.frete-gratuito.digite-aqui' | translate}}" class="form-control form-control-sm" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="100000" maxlength="7" />
                                            }
                                        </div>
                                    </div>
                                    @if (responderCompService.answerQuotation?.frete.tipo==='2') {
                                        <div class="linha form-check c-checkbox needsclick">
                                            <label class="needsclick">
                                                <input type="checkbox" id="frete_conta_comprador" value="" class="needsclick" [(ngModel)]="responderCompService.answerQuotation?.frete.conta_comprador" (change)="responderCompService.atualizarFrete()" />
                                                <span class="fa fa-check"></span>{{ 'seller.dashboard.responder.frete-gratuito.conta-comprador' | translate}}
                                            </label>
                                        </div>
                                    }
                                </div>
                            }
                            @if (responderCompService.condicoesFornecimento?.done_configuration && responderCompService.condicoesFornecimento?.fretes.length > 0) {
                                <div class="linha frete">
                                    <div class="mb-3 form-group col">
                                        <label class="col-md-12 col-form-label">{{ 'seller.dashboard.responder.frete.label' | translate}}</label>
                                        <div class="col-md-7 dados">
                                            {{ responderCompService.freteSelecionadoTexto }} {{ 'seller.dashboard.responder.frete.entrega-regiao' | translate}}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div class="linha faturamento">
                                <label class="col-md-12 col-form-label">{{ 'seller.dashboard.responder.faturamento-liberado.label' | translate}}</label>
                                <div class="linha">
                                    <div class="col">
                                        <select name="faturamento" class="form-select form-select-sm m-b" [(ngModel)]="responderCompService.answerQuotation.faturamento" (change)="responderCompService.atualizarLimiteFaturamento(responderCompService.answerQuotation?.faturamento_valor, false)" [disabled]="responderCompService.answerQuotation?.subscription_status !== 2 ? 'disabled' : null">
                                            <option value="1">{{ 'elemento.generico-select.sim' | translate}}</option>
                                            <option value="2" selected>{{ 'elemento.generico-select.nao' | translate}}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        @if (responderCompService.answerQuotation?.faturamento==='1') {
                                            <input type="text" [(ngModel)]="responderCompService.answerQuotation.faturamento_valor" placeholder="{{'seller.dashboard.responder.faturamento-liberado.digite-aqui' | translate}}" (blur)="responderCompService.atualizarLimiteFaturamento(null, false)" class="form-control form-control-sm" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" min="0" max="100000" maxlength="7" />
                                        }
                                    </div>
                                </div>
                            </div>
                            @if (!responderCompService.condicoesFornecimento?.done_configuration) {
                                <div class="linha forma-pagamento">
                                    <div class="mb-3 form-group">
                                        <label class="col-md-12 col-form-label">{{ 'seller.dashboard.responder.forma-pagamento.label' | translate}}?</label>
                                        <div class="col-md-7 dados">
                                            <select name="selected_payment_type" [disabled]="(responderCompService.answerQuotation?.faturamento !== '1' || responderCompService.formasPagamento.length <= 1) ? 'disabled' : null" [(ngModel)]="responderCompService.answerQuotation.selected_payment_type" class="form-select form-select-sm m-b ddl_tipo_pagamento">
                                                @for (tipo of responderCompService.formasPagamento; track tipo) {
                                                    <option [value]="tipo.id">{{tipo.texto_x}}</option>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            @if (responderCompService.condicoesFornecimento?.done_configuration) {
                                <div class="linha forma-pagamento">
                                    <div class="mb-3 form-group">
                                        <label class="col-md-12 col-form-label">{{ 'seller.dashboard.responder.forma-pagamento.label' | translate}}</label>
                                        <div class="col-md-7 dados" style="font-size: 1.15rem;">
                                            @if (responderCompService.tiposPagamentoMaiorQueAVista && responderCompService.answerQuotation.faturamento === '1') {
                                                <span>{{ 'seller.dashboard.responder.forma-pagamento.label-em-ate' | translate}}</span>
                                            }
                                            {{ (responderCompService.tiposPagamentoMaiorQueAVista && responderCompService.answerQuotation.faturamento === '1') ? responderCompService.tiposPagamento[responderCompService.tiposPagamento.length - 1].paymentType.texto_x : ('seller.dashboard.responder.forma-pagamento.label-a-vista' | translate) }}
                                        </div>
                                    </div>
                                </div>
                            }
                            @if (responderCompService.answerQuotation?.subscription_status !== 2) {
                                <div class="linha faturamento-pendente">
                                    <div>
                                        <span>{{ 'seller.dashboard.responder.faturamento-liberado.nao-liberado.label-4' | translate}}</span>
                                    </div>
                                </div>
                            }
                            <div class="linha notes">
                                <div class="mb-3 form-group col">
                                    <label class="col-md-12 col-form-label">{{ 'seller.dashboard.responder.observacao.label' | translate}}</label>
                                    <div class="col-md-12">
                                        <textarea class="form-control" rows="2" cols="125" [(ngModel)]="responderCompService.answerQuotation.notes_vendedor"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </ng-scrollbar>
</div>

<div class="area-botoes">
    @if (passo_atual > 1) {
        <input type="button" class="btn btn-light" value="{{ 'elemento.generico-botao.anterior' | translate}}" (click)="passo_anterior()" />
    }
    @if (passo_atual === 1) {
        <input type="button" class="btn btn-outline-danger nao-participar" value="{{ 'seller.dashboard.responder.botao.nao-quero' | translate}}" (click)="responderCompService.naoParticipar(null)" />
    }
    @if (passo_atual === passo_final) {
        <button type="button" class="btn btn-primary" (click)="responderCompService.participar(0)">{{ 'seller.dashboard.responder.botao.responder' | translate}}</button>
    }
    @if (passo_atual < passo_final) {
        <input type="button" class="btn btn-light" value="{{ 'elemento.generico-botao.proximo' | translate}}" [disabled]="!liberar_proximo" (click)="passo_proximo()" />
    }
</div>
