import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { AccountService } from '../../account/account.service';

import { Globals } from '../../_models/globals';

@Component({ selector: 'app-feedback', templateUrl: './feedback.component.html', styleUrls: ['./feedback.component.scss'] })
export class ExternalFeedbackComponent implements OnInit
{
    imagem: string;
    subtitulo: string;
    titulo: string;
    botaoVoltar: string;
    isInternalSeller: boolean = false;

    constructor(private globals: Globals, private route: ActivatedRoute, public accountService: AccountService) { }

    ngOnInit(): void
    {
        var tipo = this.route.snapshot.params.tipo;
        this.isInternalSeller = this.accountService.checkIfIsSeller()
        this.botaoVoltar = this.globals.translate('elemento.generico-botao.voltar');

        switch (tipo)
        {

            case "participando": // Link Rapido - Participando
                this.imagem = "participando";
                this.titulo = this.globals.translate('geral.feedback.link-rapido.participando.titulo');
                this.subtitulo = this.globals.translate(`geral.feedback.link-rapido.participando.subtitulo.${this.isInternalSeller ? 'interno' : 'externo'}`);
                this.accountService.reloadWindow();

                if(!this.isInternalSeller) {
                    setTimeout(() => {
                        window.close();
                    }, 10000);
                }

                break;

            case "nao-participando": // Link Rapido - Não Participando
                this.imagem = "nao-participando";
                this.titulo = this.globals.translate('geral.feedback.link-rapido.nao-participando.titulo');
                this.subtitulo = this.globals.translate('geral.feedback.link-rapido.nao-participando.subtitulo');

                break;

            case "nao-encontrado": // Link Rapido - Não Encontrado
                this.imagem = "nao-encontrado";
                this.titulo = this.globals.translate('geral.feedback.link-rapido.nao-encontrado.titulo');
                this.subtitulo = this.globals.translate('geral.feedback.link-rapido.nao-encontrado.subtitulo');

                break;

            default:
                this.imagem = "ops";
                this.titulo = this.globals.translate('geral.feedback.link-rapido.ops.titulo');
                this.subtitulo = this.globals.translate('geral.feedback.link-rapido.ops.subtitulo');
                break;
        }
    }

    irTelaVisaoGeral() {
        var usuario = this.accountService.obterUsuario();
        this.accountService.navegarHomePage(usuario.homePage);
    }
}
