import { Injectable } from '@angular/core';

import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';
import { Answer } from './_models/answer';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class SellerResponderService extends BaseService {
    private _domain: string = '/seller/quotations';
    private _domainExternalSeller: string = '/external-seller';

    constructor(private config: SettingsService) {
        super();
    }

    public chooseToNotParticipate(quotation_id: number, reason_id: number, token: string) {
        const body = { reason_id, token };
        let url = `${this._domain}/${quotation_id}/choose-to-not-participate`;

        if (!isEmpty(token)){
            url = `${this._domainExternalSeller}/quotations/${quotation_id}/choose-to-not-participate`;
        }

        return this.config.executePost({ url: url, body });
    }

    public chooseToParticipate(answer: Answer) {
        let url = `${this._domain}/${answer.quotation_id}/choose-to-participate`;

        if (!isEmpty(answer.token)){
            url = `${this._domainExternalSeller}/quotations/${answer.quotation_id}/choose-to-participate`;
        }

        return this.config.executePost({ url: url, body: answer });
    }

    public get(quotation_id: number) {
        return this.config.executeGet({ url: `${this._domain}/${quotation_id}` });
    }

    public getPartDetails(itemId: number, newPartNumber: string) {
        return this.config.executeGet({ url: `${this._domain}/items/${itemId}?newPartNumber=${newPartNumber}`});
    } 

    public getExternalSeller(token_pedido: string) {
        return this.config.executeGet({ url: `${this._domainExternalSeller}/${token_pedido}` });
    }
}
