import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EMPTY } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { GeneralService } from 'app/_services/general.service';
import { NotificationService } from 'app/_services/notification.service';
import { SettingsService } from 'app/_services/settings.service';
import { StatusIdEnum, isAwaitingConfirmation, isOldStatusConfirmado } from 'app/_models/statusId';

declare const $: any;
declare const isEmpty: any;
declare const numberWithTwoDecimals: any;
declare const numberToPtBr: any;

@Injectable({ providedIn: 'root' })
export class SellerService extends BaseService
{
    private _domain: string = '/seller/orders';
    dadosCotacaoPedidoSelecionado: any;

    constructor(private accountService: AccountService, private generalService: GeneralService, private notificationService: NotificationService, private config: SettingsService, private router: Router,
        private translate: TranslateService)
    {
        super();
    }

    atualizarTotais(pedido: any)
    {
        pedido.total = this.obterTotalNumerico(pedido);
        pedido.total_com_frete = this.obterTotalNumericoComFrete(pedido);

        return pedido;
    }

    public async obter(order_id: number)
    {
        return this.getOrderDetail(order_id).toPromise();
    }

    public finalizarObtencaoPedido(pedido, fotos, order_id: number) {
        if (pedido == null) {
            if (!isEmpty(order_id))
                this.voltar({ corpo: 'seller.dashboard.detalhes.mensagens.erro.carregamento.nao-encontrado', parametro: order_id });

            return;
        }

        pedido.alguemParaAcao = false;
        pedido.alguemSelecionado = false;
        pedido.statusEmAnalise = pedido.pecas.filter(p => p.status == StatusIdEnum.EmAnalise).length > 0;
        pedido.statusNaoConfirmado = pedido.pecas.filter(p => p.status == StatusIdEnum.Pedido || p.status == StatusIdEnum.EmAnalise).length > 0;

        for (let i = 0; i <= pedido.pecas.length - 1; i++) {
            if (isOldStatusConfirmado(pedido.pecas[i].status))
            {
                pedido.alguemParaAcao = true;
                break;
            }
        }

        let todasPecasCanceladas = (pedido.pecas.length === pedido.pecas.filter(p => p.status >= StatusIdEnum.CotacaoCanceladaComprador).length);
        pedido.todasCanceladas = todasPecasCanceladas;

        pedido.fotos = fotos;
        pedido = this.atualizarTotais(pedido);

        this.dadosCotacaoPedidoSelecionado = pedido;
    }

    public getOrderDetail(order_id: number) {
        return this.config.executeGet({ url: `${this._domain}/${order_id}` });
    }

    public imprimir(pageComponent, elements)
    {
        pageComponent.ngxLoader.startLoader('loader-principal');

        let jsonData = [];

        let cabecalhoQuantidade = pageComponent.globals.translate('seller.dashboard.detalhes.tabela.quantidade').toString().removeAccent();
        let cabecalhoPeca = pageComponent.globals.translate('seller.dashboard.detalhes.tabela.peca').toString().removeAccent();
        let cabecalhoValor = pageComponent.globals.translate('seller.dashboard.detalhes.tabela.valor').toString().removeAccent();
        let cabecalhoDesconto = pageComponent.globals.translate('seller.dashboard.detalhes.tabela.desconto').toString().removeAccent();
        let cabecalhoPrazo = pageComponent.globals.translate('seller.dashboard.detalhes.tabela.prazo.label').toString().removeAccent();
        let cabecalhoTipoPeca = pageComponent.globals.translate('seller.dashboard.detalhes.tabela.tipo-peca').toString().removeAccent();
        let cabecalhoStatus = pageComponent.globals.translate('seller.dashboard.detalhes.tabela.status').toString().removeAccent();
        let cabecalhoTotal = pageComponent.globals.translate('seller.dashboard.detalhes.tabela.total').toString().removeAccent();

        const obterTotalPeca = this.obterTotalPeca;
        const obterTotalPecaNumerico = this.obterTotalPecaNumerico;

        $.each(pageComponent.pedido.pecas, function (i, peca) {
            let row = {};
            row[cabecalhoQuantidade] = peca.quantidade;
            row[cabecalhoPeca] = peca.peca;
            row[cabecalhoValor] = 'R$' + numberToPtBr(peca.valor_unitario);
            row[cabecalhoDesconto] = numberToPtBr(peca.desconto) + '%';
            row[cabecalhoPrazo] = peca.prazo_entrega;
            row[cabecalhoTipoPeca] = peca.tipo_peca;
            row[cabecalhoStatus] = peca.status_peca;

            const total = obterTotalPeca(peca, obterTotalPecaNumerico);
            row[cabecalhoTotal] = 'R$' + numberToPtBr(total);

            jsonData.push(row);
        });

        let wrapperHeader = document.createElement('div');
        let outerHTML = `${elements.vehicle.outerHTML}<br /><br />${elements.header.outerHTML}`;
        wrapperHeader.innerHTML = outerHTML;
        $(wrapperHeader.firstChild).append(elements.subheader.outerHTML);

        let wrapperFooter = document.createElement('div');
        wrapperFooter.innerHTML = elements.footer.outerHTML;

        let header = $(wrapperHeader.firstChild)[0].outerHTML;
        let footer = $(wrapperFooter.firstChild)[0].outerHTML;

        let printPageData = {
            type: 'print-vendedor',
            header: header,
            data: jsonData,
            footer: footer
        };

        this.generalService.printPageData = printPageData;

        elements.printView.attr('src', '/imprimir');
        pageComponent.ngxLoader.stopLoader('loader-principal');
    }

    public obterStatusClassName(status: StatusIdEnum) {
        switch (status) {
            case StatusIdEnum.Confirmado:
            case StatusIdEnum.Entregue:
            case StatusIdEnum.AguardandoDevolucao:
            case StatusIdEnum.AguardandoTroca:
                return 'success';
            case StatusIdEnum.EmAnalise:
                return 'warning';
            default:
                return isAwaitingConfirmation(status) ? 'yellow' : 'danger';
        }
    }

    public obterTotal(pedido: any) {
        const total = this.obterTotalNumerico(pedido);
        return total;
    }

    public obterTotalNumerico(pedido: any): number {
        if (isEmpty(pedido)) { return 0; }

        const pecas = pedido.pecas;
        let total = 0;
        const obterTotalPecaNumerico = this.obterTotalPecaNumerico;

        if (!isEmpty(pecas))
        {
            $.each(pecas, function (i, peca)
            {
                var status = isEmpty(peca.status) ? peca.status_id : peca.status;

                if (status <= StatusIdEnum.AguardandoDevolucao || status == StatusIdEnum.Entregue)
                {
                    total += obterTotalPecaNumerico(peca);
                }
            });
        }

        return total;
    }

    public obterTotalNumericoComFrete(pedido: any): number
    {
        if (isEmpty(pedido)) { return 0; }

        const pecas = pedido.pecas;
        let total = 0;
        const obterTotalPecaNumerico = this.obterTotalPecaNumerico;

        if (!isEmpty(pecas))
        {
            $.each(pecas, function (i, peca)
            {
                var status = isEmpty(peca.status) ? peca.status_id : peca.status;

                if (status <= StatusIdEnum.AguardandoDevolucao || status == StatusIdEnum.Entregue)
                {
                    total += obterTotalPecaNumerico(peca);
                }
            });
        }

        total += isEmpty(pedido.frete_valor) || pedido.frete_valor === 0 ? 0 : pedido.frete_valor;

        return total;
    }

    public obterTotalPeca(peca, fnObterTotalPecaNumerico = null)
    {
        fnObterTotalPecaNumerico = fnObterTotalPecaNumerico || this.obterTotalPecaNumerico;

        if (peca.valor_unitario == undefined || peca.desconto == undefined)
        {
            return '0,00';
        }

        // tslint:disable-next-line: prefer-const
        let total = fnObterTotalPecaNumerico(peca);
        return total;
    }

    public obterTotalPecaNumerico(peca)
    {
        if (peca == undefined || peca.valor_unitario == undefined || peca.desconto == undefined || (peca.hasOwnProperty('respondida') && !peca.respondida))
        {
            return 0;
        }

        var quantity = peca.quantity_in_stock ?? peca.quantidade

        return numberWithTwoDecimals(quantity * (peca.valor_unitario - (peca.valor_unitario * peca.desconto / 100)));
    }

    public voltar(errorMessage: { corpo: string, parametro: any } | null)
    {
        if (!isEmpty(errorMessage))
        {
            this.translate.get(errorMessage.corpo).subscribe(corpo =>
            {
                this.notificationService.showErrorToastr(corpo.replace("#xxx", "#" + errorMessage.parametro));
            });
        }

        this.router.navigate(['/vendedor/visao-geral']);
    }
}
