import { UntypedFormGroup, AbstractControl } from "@angular/forms";

declare const isEmpty: any;

export function ResponderMarcaValidator()
{
    return (control: AbstractControl, formGroup: UntypedFormGroup) =>
    {
        var ptControl = formGroup.controls['peca_part_type'];
        var part_type = parseInt(ptControl.value);

        let isValid = part_type > 1 ? !isEmpty(control.value) : false;

        return (!isValid) ? { required: true } : null;
    };
}
