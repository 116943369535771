import { Injectable } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { NotificationService } from 'app/_services/notification.service';
import { SellerResponderService } from '../responder.service';
import { SellerService } from '../../seller.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Answer } from '../_models/answer';
import { AnswerOutReason } from '../_models/answerOutReason';
import { AnswerQuotation } from '../_models/answerQuotation';
import { CondicaoFornecimento } from 'app/_models/condicaoFornecimento';
import { DeliveryTime } from 'app/buyer/_models/deliveryTime';
import { getScreenSizeIsMobile } from 'app/_utils/general-utils';
import { Globals } from 'app/_models/globals';
import { PaymentType } from 'app/_models/paymentType';
import { Peca } from '../_models/peca';
import { ResponderMarcaValidator } from 'app/_utils/validators/responder-marca.validator';
import { SweetAlertParams } from 'app/_models/notificationParams.model';
import { User } from 'app/_models/user';

declare const $: any;
declare const isEmpty: any;
declare const sort_by: any;
declare const waitForElm: any;

@Injectable({ providedIn: 'root' })
export class SellerResponderComponentService
{
    canShowForm: boolean = false;
    condicoesFornecimento: CondicaoFornecimento;
    formasPagamento: PaymentType[];
    isOnError: boolean;
    menor1024: boolean;
    motivosNaoParticipacao: AnswerOutReason[];
    novo_limite: number;
    part_types: { genuina: boolean, original: boolean, alternativa: boolean } = { genuina: false, original: false, alternativa: false };
    prazosEntrega: DeliveryTime[];

    private quotation_id: number;
    token: string;
    answerQuotation: AnswerQuotation;
    responderForm: UntypedFormGroup;
    usuarioLogado: User;

    Swal: any;

    constructor(private accountService: AccountService, private baseService: BaseService, private notificationService: NotificationService, private responderService: SellerResponderService, private sellerService: SellerService,
        private globals: Globals, private formBuilder: UntypedFormBuilder, private ngxLoader: NgxUiLoaderService, private router: Router)
    {
        this.menor1024 = getScreenSizeIsMobile();
        this.usuarioLogado = this.baseService.usuarioLogado;
        this.Swal = require('sweetalert2');
    }

    atualizarFrete(pre_item_answer_shipping_cost: number) {
        if (this.answerQuotation.frete.tipo === "1")
        {
            this.answerQuotation.frete.gratuito = true;
            this.answerQuotation.frete.conta_comprador = this.answerQuotation.frete.nao_entrega = false;
            this.answerQuotation.frete.valor = null;
        }
        else
        {
            this.answerQuotation.frete.gratuito = false;

            if (this.answerQuotation.frete.conta_comprador || this.answerQuotation.frete.nao_entrega)
                this.answerQuotation.frete.valor = null;
            else if (isEmpty(this.answerQuotation.frete.valor) || this.answerQuotation.frete.valor === 0)
                this.answerQuotation.frete.valor = isEmpty(pre_item_answer_shipping_cost) ? null : pre_item_answer_shipping_cost;

            var frete_valor_disabled = (this.answerQuotation.frete.conta_comprador || this.answerQuotation.frete.nao_entrega);
            var frete_nao_entrega_disabled = this.answerQuotation.frete.conta_comprador;
            var frete_conta_comprador_disabled = this.answerQuotation.frete.nao_entrega;

            $('#frete_valor').attr('disabled', frete_valor_disabled);
            $('#frete_nao_entrega').attr('disabled', frete_nao_entrega_disabled);
            //$('#frete_conta_comprador').attr('disabled', frete_conta_comprador_disabled);
        }
    }

    atualizarLimiteFaturamento(pre_item_answer_limit: number, force_block_payment_type: boolean) {
        if (this.answerQuotation.faturamento === '1')
        {
            let total = isEmpty(pre_item_answer_limit) ? (isEmpty(this.answerQuotation.faturamento_valor) ? this.sellerService.obterTotalNumerico(this.answerQuotation) : this.answerQuotation.faturamento_valor) : pre_item_answer_limit;
            this.answerQuotation.faturamento_valor = total;
        }
        else
        {
            this.answerQuotation.faturamento_valor = null;
            this.answerQuotation.selected_payment_type = '0';

            if (force_block_payment_type) {
                waitForElm(`.ddl_tipo_pagamento`).then((elm) =>
                {
                    $('.ddl_tipo_pagamento').attr('disabled', true);
                });
            }
        }
    }

    getPartType(inventoryPartType?: number) {
        const isGenuina = (inventoryPartType & 1) == 1; // 1, 3, 5, 7
        const isOriginal = (inventoryPartType & 2) == 2; // 2, 3, 6, 7
        const isIntegrated = inventoryPartType > 0;

        if (isGenuina || !isIntegrated) {
            if (this.part_types.genuina)
                return 1;
    
            if (this.part_types.original)
                return 2;
            
            return 4;
        }        

        // this.part_types.original = true pois o fornecedor fornece original (2, 3, 6, 7) e a cotação aceita original
        // isOriginal = true quando a peça no estoque do fornecedor é original (2, 3, 6, 7)
        if (isOriginal) {
            if (this.part_types.original)
                return 2;

            if (this.part_types.genuina)
                return 1;

            return 4;
        }

        // Alternativa
        if (this.part_types.alternativa)
            return 4;

        if (this.part_types.genuina)
            return 1;

        return 2;
    }

    atualizarFormularioPecas(pecas: Peca[]) {
        pecas.map((peca: Peca) => {
            if (this.condicoesFornecimento.done_configuration && this.condicoesFornecimento.prazosEntrega.length > 0 && peca.delivery_time <= 0)
                peca.delivery_time = this.condicoesFornecimento.prazosEntrega[0].delivery_time_id;

            peca.block_edit = false;

            if (peca.respondida)
                peca.respondida = !this.menor1024;

            peca.desabilitar_marca = true;

            peca.part_type = this.getPartType(peca.part_type);

            this.atualizarTipoPeca(peca);

            if (peca.quantidade === 1) {
                peca.quantity_in_stock = 1;
                peca.desabilitar_quantity_in_stock = true;
            }

            this.responderForm.addControl(`_${peca.item_id}`, this.formBuilder.group({
                    peca_new_part_code: ['', Validators.required],
                    peca_price: ['', Validators.required],
                    peca_discount: ['', Validators.required],
                    peca_delivery_time: ['', Validators.required],
                    peca_part_type: ['', Validators.required],
                    peca_brand: ['', ResponderMarcaValidator],
                    peca_quantity_in_stock: [{ value: '', disabled: peca.desabilitar_quantity_in_stock },
                    [Validators.required, Validators.min(1), Validators.max(peca.quantidade)]],
                }
            ));

            this.canShowForm = true;
        });
    }

    atualizarTipoPeca(peca: Peca) {
        // Se o tipo da peça estiver vazia (undefined) ou for do tipo genuína, desabilita o campo de marca
        if (isEmpty(peca.part_type) || peca.part_type === 0 || peca.part_type == 1) {
            peca.brand = undefined;
            peca.desabilitar_marca = true;
        }
        else
            peca.desabilitar_marca = false;
    }

    carregarDetalhes(quotation_id: number, nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        if (isEmpty(quotation_id)) {
            this.voltar({ corpo: 'seller.dashboard.mensagens.listar.erro.nao-encontrada', parametro: quotation_id });
            return;
        }

        this.responderService.get(quotation_id).subscribe({
            next: (response: ApiResponseModel<AnswerQuotation>) => {
                if (response && response.success) {
                    if (!response.result) {
                        this.voltar({ corpo: 'seller.dashboard.mensagens.listar.erro.nao-encontrada', parametro: quotation_id });
                        return;
                    }

                    let formasPagamento = Object.assign([], response.result.formasPagamento);
                    let avista = formasPagamento.splice(0, 1)[0];
                    avista.texto_x = avista.texto;
                    formasPagamento = formasPagamento.sort(sort_by([{ name: 'texto_x' }]));
                    formasPagamento.unshift(avista);

                    this.formasPagamento = formasPagamento;
                    this.prazosEntrega = response.result.prazosEntrega;
                    response.result.motivosNaoParticipacao.sort(sort_by([{ name: 'name' }]));
                    this.motivosNaoParticipacao = response.result.motivosNaoParticipacao;

                    // Busca as informações de condições de fornecimento
                    this.condicoesFornecimento = response.result.vendedorCondicaoFornecimento;

                    this.answerQuotation = response.result.quotation;
                    this.answerQuotation.fotos = response.result.fotos;

                    // Atualiza os partTypes
                    this.setPartType()

                    // Atualiza o form com as peças
                    this.atualizarFormularioPecas(response.result.quotation.pecas);

                    // Se não tiver frete definido, seleciona frete gratuito
                    this.answerQuotation.frete.tipo = isEmpty(this.answerQuotation.frete) || isEmpty(this.answerQuotation.frete.tipo) ? "1" : this.answerQuotation.frete.tipo.toString();
                    this.atualizarFrete(this.answerQuotation.frete.valor);

                    // Se não tiver faturamento definido ou estiver com faturamento do cliente não definido, seleciona faturamento - Não
                    this.answerQuotation.faturamento = isEmpty(this.answerQuotation.faturamento) || this.answerQuotation.subscription_status !== 2 ? "2" : this.answerQuotation.faturamento.toString();
                    this.atualizarLimiteFaturamento(this.answerQuotation.faturamento_valor, true);

                    this.novo_limite = 0;

                    if (!this.menor1024 && (isEmpty(this.condicoesFornecimento) || !this.condicoesFornecimento.done_configuration))
                        this.sugerirConfigurarCondicoesFornecimento();
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                if (error.status === 404) {
                    this.voltar({ corpo: 'seller.dashboard.mensagens.listar.erro.nao-encontrada', parametro: quotation_id });
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.voltar({ corpo: 'seller.dashboard.mensagens.listar.erro.nao-encontrada', parametro: quotation_id });
                    return;

                }

                this.carregarDetalhes(quotation_id, ++nAttempts);
            }
        });
    }

    carregarDetalhesPedidoExterno(token_pedido: string, nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.responderService.getExternalSeller(token_pedido).subscribe({
            next: (response: ApiResponseModel<AnswerQuotation>) => {
                if (response && response.success) {
                    this.answerQuotation = response.result.quotation;

                    if (this.answerQuotation === null) {
                        this.goToFeedback('nao-encontrado');
                        return;
                    }

                    this.answerQuotation.fotos = response.result.fotos;

                    this.formasPagamento = response.result.formasPagamento;
                    this.prazosEntrega = response.result.prazosEntrega;
                    this.motivosNaoParticipacao = response.result.motivosNaoParticipacao;

                    // Busca as informações de condições de fornecimento
                    this.condicoesFornecimento = response.result.vendedorCondicaoFornecimento;

                    // Atualiza os partTypes
                    this.setPartType()

                    // Atualiza o form com as peças
                    this.atualizarFormularioPecas(this.answerQuotation.pecas);

                    // Se não tiver frete definido, seleciona frete gratuito
                    this.answerQuotation.frete.tipo = isEmpty(this.answerQuotation.frete) || isEmpty(this.answerQuotation.frete.tipo) ? "1" : this.answerQuotation.frete.tipo.toString();
                    this.atualizarFrete(this.answerQuotation.frete.valor);

                    // Se não tiver faturamento definido ou estiver com faturamento do cliente não definido, seleciona faturamento - Não
                    this.answerQuotation.faturamento = isEmpty(this.answerQuotation.faturamento) || this.answerQuotation.subscription_status !== 2 ? "2" : this.answerQuotation.faturamento.toString();
                    this.atualizarLimiteFaturamento(this.answerQuotation.faturamento_valor, true);

                    this.novo_limite = 0;
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                if (error.statusCode === 404) {
                    this.goToFeedback('nao-encontrado');
                    return;
                }

                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.goToFeedback('ops');
                    return;
                }

                this.carregarDetalhesPedidoExterno(token_pedido, ++nAttempts);
            }
        });
    }

    getPartDetails(part: Peca, index: number) {
        this.responderService.getPartDetails(part.item_id, part.new_part_code || part.code).subscribe({
            next: async (response: ApiResponseModel<Peca>) => {
                if (response && response.result) {
                    let partResult = response.result;

                    this.answerQuotation.pecas[index] = partResult;
                    this.answerQuotation.pecas[index].part_type = this.getPartType(partResult.part_type);
                    this.answerQuotation.pecas[index].respondida = true;
                    this.atualizarTipoPeca(this.answerQuotation.pecas[index]);

                    if (partResult.quantity_in_stock === 0) {
                        this.answerQuotation.pecas[index].quantity_in_stock = 1;
                        this.answerQuotation.pecas[index].respondida = true;
                        await this.notificationService.showWarning('seller.pedidos.responder.buscar-peca.peca-encontrada-estoque-zero');
                        return;
                    }

                    if (partResult.quantity_in_stock < part.quantidade) {
                        await this.notificationService.showWarning(('seller.pedidos.responder.buscar-peca.peca-encontrada-estoque-menor'));
                    }
                } else {
                    await this.notificationService.showWarning(('seller.pedidos.responder.buscar-peca.peca-nao-encontrada'));
                    this.answerQuotation.pecas[index].respondida = false;
                    this.answerQuotation.pecas[index].quantity_in_stock = 0;
                    this.answerQuotation.pecas[index].valor_unitario = 0;
                    this.answerQuotation.pecas[index].desconto = 0;
                    this.answerQuotation.pecas[index].delivery_time = null;
                    this.answerQuotation.pecas[index].part_type = this.getPartType(null);
                    this.atualizarTipoPeca(this.answerQuotation.pecas[index]);
                }
            },
            error: () => {
                this.notificationService.showErrorToastr(this.globals.translate('seller.pedidos.responder.buscar-peca.peca-erro'));
                this.answerQuotation.pecas[index] = {} as Peca;
                return;
            }
        });
    }

    goToConfigurar() {
        this.router.navigate(['/vendedor/configurar-condicoes-fornecimento']);
    }

    goToFeedback(tipo: string) {
        this.router.navigate(['/pedido/feedback/' + tipo]);
    }

    naoParticipar(nAttempts: number) {
        const options = `{ ${this.motivosNaoParticipacao.map((m: any) => (`"_${m.id}":"${m.name}"`)).join(',')} }`;

        let params: SweetAlertParams = {
            title: 'seller.dashboard.responder.nao-participar.subtitulo',
            input: 'select',
            inputOptions: JSON.parse(options),
            inputPlaceholder: 'seller.dashboard.responder.nao-participar.selecione',
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (!isEmpty(value))
                        resolve(null);
                    else
                        resolve(this.globals.translate('seller.dashboard.responder.nao-participar.selecione-erro'));
                })
            },
            icon: 'warning',
            cancelButtonText: 'seller.dashboard.responder.nao-participar.botao.fechar',
            confirmButtonText: 'seller.dashboard.responder.nao-participar.botao.responder'
        }

        this.notificationService.showConfirm(params).then((result) => {
            if (result.value) {
                this.ngxLoader.startLoader('loader-principal');

                this.responderService.chooseToNotParticipate(this.answerQuotation.quotation_id, result.value.replace('_', ''), this.token).subscribe({
                    next: (response: ApiResponseModel<boolean>) => {
                        if (response && response.success && response.result) {
                            this.toastrExecutar(true, this.globals.translate('seller.dashboard.responder.mensagens.sucesso.nao-participar.corpo'));
                        } else {
                            this.toastrExecutar(false, this.globals.translate('seller.dashboard.responder.mensagens.erro.nao-participar.corpo'));
                        }

                        if (isEmpty(this.token))
                            this.voltar(null);
                        else
                            this.goToFeedback('nao-participando');

                        this.ngxLoader.stopLoader('loader-principal');
                    },
                    error: error => {
                        nAttempts = nAttempts || 1;
                        console.log(error, nAttempts);

                        if (nAttempts >= 5) {
                            this.toastrExecutar(false, this.globals.translate('seller.dashboard.responder.mensagens.erro.nao-participar.corpo'));
                            return;
                        }

                        this.ngxLoader.stopLoader('loader-principal');
                        this.naoParticipar(++nAttempts);
                    }
                });
            }
        });
    }

    async participar(nAttempts: number) {
        var pecas = this.answerQuotation.pecas.filter(p => p.respondida).map(function (p) {
            p.brand = isEmpty(p.brand) || p.brand.trim().length === 0 ? null : p.brand.trim();
            p.new_part_code = isEmpty(p.new_part_code) || p.new_part_code.trim().length === 0 ? null : p.new_part_code.trim();
            p.part_type = parseInt(p.part_type?.toString());
            p.delivery_time = parseInt(p.delivery_time?.toString());

            return p;
        });

        let seller_company_id = this.accountService.checkIfIsSeller() ? this.baseService.usuarioLogado.user_company_id : 0;

        let can_be_financed = this.answerQuotation.faturamento === "1";
        let tipos = this.tiposPagamento.slice();

        let selected_payment_type = 0;

        if (can_be_financed) {
            if (this.condicoesFornecimento.done_configuration)
                selected_payment_type = tipos.length === 0 ? 0 : tipos.slice(-1)[0]['payment_type_id'];
            else
                selected_payment_type = parseInt(this.answerQuotation.selected_payment_type);
        }

        let resposta: Answer = {
            quotation_id: this.answerQuotation.quotation_id,
            seller_company_id: seller_company_id,
            frete_gratuito: this.freteSelecionado.frete_gratuito,
            shipping_cost: this.freteSelecionado.shipping_cost,
            limit: this.answerQuotation.faturamento === "2" || isEmpty(this.answerQuotation.faturamento_valor) || this.answerQuotation.faturamento_valor === 0 ? null : this.answerQuotation.faturamento_valor.round(2),
            buyer_responsability: this.freteSelecionado.buyer_responsability,
            can_be_financed: can_be_financed,
            deliver: this.freteSelecionado.buyer_responsability ? false : this.freteSelecionado.deliver,
            payment_type: selected_payment_type,
            notes: this.answerQuotation.notes_vendedor,
            pecas,
            token: this.token
        };

        // Realiza a validação dos dados
        var correcoes = this.validarResposta(resposta);

        if (correcoes !== '') {
            let params: SweetAlertParams = {
                bigSwall: true,
                title: 'seller.dashboard.responder.participar.erros.titulo',
                message: correcoes,
                isHtml: true,
            }

            await this.notificationService.showInfoWithoutButtons(params)

            return;
        }

        if (!(await this.notificationService.showConfirmationDialog('seller.dashboard.responder.participar.titulo')))
            return;

        this.ngxLoader.startLoader('loader-principal');

        this.responderService.chooseToParticipate(resposta).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response && response.success && response.result) {
                    this.toastrExecutar(true, this.globals.translate('seller.dashboard.responder.mensagens.sucesso.participar.corpo'));
                } else {
                    this.toastrExecutar(false, this.globals.translate('seller.dashboard.responder.mensagens.erro.participar.corpo'));
                }

                if (!isEmpty(this.token))
                    this.goToFeedback('participando');
                else if(getScreenSizeIsMobile())
                    this.router.navigate(['/vendedor/visao-geral/responder/feedback/participando']);
                else
                    this.voltar(null);

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.toastrExecutar(false, this.globals.translate('seller.dashboard.responder.mensagens.erro.participar.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.participar(++nAttempts);
            }
        });
    }

    setPartType() {
        if (!this.accountService.checkIfIsSeller()) {
            this.part_types = {
                genuina: true,
                original: true,
                alternativa: true
            }
            return
        }

        this.part_types = {
            genuina: this.usuarioLogado.holding_type_id == 2 || ((this.usuarioLogado.part_type & 1) == 1 && this.answerQuotation.part_type_genuina),
            original: this.answerQuotation.part_type_original && (this.usuarioLogado.part_type & 2) == 2 && this.usuarioLogado.holding_type_id != 2,
            alternativa: this.answerQuotation.part_type_alternativa && (this.usuarioLogado.part_type & 4) == 4 && this.usuarioLogado.holding_type_id != 2
        }
    }

    onInit(param_id: string) {
        this.canShowForm = false;

        this.responderForm = this.formBuilder.group({
            frete: [{ tipo: '' }, Validators.required],
            frete_tipo: ['', Validators.required],
            frete_valor: ['', Validators.required],
            frete_nao_entrega: ['', Validators.required],
            frete_conta_comprador: [{ value: '', disabled: (this.answerQuotation?.frete.nao_entrega) }, Validators.required],
            faturamento: ['', Validators.required],
            faturamento_valor: ['', Validators.required],
            selected_payment_type: ['', Validators.required],
            novo_limite: ['', Validators.required],
            notes_vendedor: ['', Validators.required],
            peca_price: ['', Validators.required],
            peca_discount: ['', Validators.required],
            peca_delivery_time: ['', Validators.required],
            peca_part_type: ['', Validators.required],
            peca_brand: ['', Validators.required]
        });

        // Se estiver logado
        if (this.accountService.checkIfIsSeller()) {
            // Obtém o id da cotação passado por parâmetro
            var isValid = /^\d+$/.test(param_id);
            this.isOnError = !isValid;

            if (isValid)
            {
                this.quotation_id = parseInt(param_id.toString());
                // tslint:disable-next-line: radix
                this.carregarDetalhes(this.quotation_id, 0);
            }
        }
        else { // Caso não esteja logado, tenta obter o id de vendedor externo
            this.isOnError = isEmpty(param_id);

            if (this.isOnError)
                return;

            this.token = param_id;

            // Busca os dados da cotação baseado no código do pedido passado ao vendedor externo
            this.carregarDetalhesPedidoExterno(param_id, 0);
        }
    }

    sugerirConfigurarCondicoesFornecimento() {
        let params: SweetAlertParams = {
            title: 'seller.dashboard.responder.condicoes-fornecimento.configurar.titulo',
            message: this.accountService.usuarioLogado.firstName + this.globals.translate('seller.dashboard.responder.condicoes-fornecimento.configurar.subtitulo'),
            notTranslateMessage: true,
            icon: 'warning',
            cancelButtonText: 'seller.dashboard.responder.condicoes-fornecimento.configurar.botoes.fechar',
            confirmButtonText: 'seller.dashboard.responder.condicoes-fornecimento.configurar.botoes.confirmar'
        }

        this.notificationService.showConfirm(params).then((result) => {
            if (result.value)
                this.goToConfigurar();
        });
    }

    toastrExecutar(is_sucesso: boolean, corpo: string) {
        if (this.menor1024) {
            alert(corpo);
            return;
        }

        if (is_sucesso)
            this.notificationService.showSuccessToastr(corpo);
        else
            this.notificationService.showErrorToastr(corpo);
    }

    validarResposta(resposta: Answer) {
        var correcoes = '';

        // Pelo menos uma peça deve estar preenchida
        if (resposta.pecas.length === 0)
            correcoes += '<div>' + this.globals.translate('seller.dashboard.responder.participar.erros.pelo-menos-uma-peca') + '</div>';
        else { // Caso existam peças preenchidas, valida se os dados estão corretamente preechidos
            resposta.pecas.map((peca: Peca) => {
                var correcaoPeca = '';

                if (isEmpty(peca.valor_unitario) || peca.valor_unitario <= 0)
                    correcaoPeca += '<li> ' + this.globals.translate('seller.dashboard.responder.participar.erros.peca.preco-invalido') + '</li>';

                    if (isEmpty(peca.desconto) || peca.desconto < 0)
                        correcaoPeca += '<li> ' + this.globals.translate('seller.dashboard.responder.participar.erros.peca.desconto-invalido') + '</li>';

                if (isEmpty(peca.delivery_time) || peca.delivery_time <= 0)
                    correcaoPeca += '<li> ' + this.globals.translate('seller.dashboard.responder.participar.erros.peca.prazo-entrega-invalido') + '</li>';

                if (isEmpty(peca.part_type) || peca.part_type <= 0)
                    correcaoPeca += '<li> ' + this.globals.translate('seller.dashboard.responder.participar.erros.peca.tipo-peca.invalido') + '</li>';
                else if (peca.part_type > 1 && isEmpty(peca.brand))
                    correcaoPeca += '<li> ' + this.globals.translate('seller.dashboard.responder.participar.erros.peca.tipo-peca.marca-invalida') + '</li>';

                if (isEmpty((peca.quantity_in_stock)) || peca.quantity_in_stock === 0)
                    correcaoPeca += '<li> ' + this.globals.translate('seller.dashboard.responder.participar.erros.peca.quantidade-em-estoque-invalido') + '</li>';

                if (correcaoPeca !== '') {
                    correcoes += '<ul style="text-align: left"><li>Peça: ' + peca.name + '<ul>';
                    correcoes += correcaoPeca;
                    correcoes += '</ul></li></ul>'
                    // correcoes += '<span style="display: inline-block; width: 95%; text-align: left">Peça: ' + peca.name + '</span><ul style="text-align: left">';
                    // correcoes += correcaoPeca;
                    // correcoes += '</ul>'
                }
            });
        }

        // Verifica o frete
        if (!resposta.frete_gratuito && !resposta.buyer_responsability && resposta.deliver && (isEmpty(resposta.shipping_cost) || resposta.shipping_cost <= 0))
            correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('seller.dashboard.responder.participar.erros.frete.dados-invalidos') + '</li></ul>';

        // verifica o faturamento
        if (resposta.can_be_financed && (isEmpty(resposta.limit) || resposta.limit <= 0))
            correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('seller.dashboard.responder.participar.erros.faturamento-indefinido') + '</li></ul>';

        // verifica a forma de pagamento
        if (resposta.payment_type === undefined || resposta.payment_type === null)
            correcoes += '<ul style="text-align: left"><li>' + this.globals.translate('seller.dashboard.responder.participar.erros.forma-pagamento-indefinido') + '</li></ul>';

        return correcoes;
    }

    voltar(errorMessage: { corpo: string, parametro: any }) {
        if (!isEmpty(errorMessage))
            this.toastrExecutar(false, this.globals.translate(errorMessage.corpo).replace("#xxx", "#" + errorMessage.parametro));

        this.router.navigate(['/vendedor/visao-geral']);
    }


    get freteSelecionado(): any {
        if (isEmpty(this.condicoesFornecimento) || !this.condicoesFornecimento.done_configuration || isEmpty(this.condicoesFornecimento.fretes))
            return {
                frete_gratuito: this.answerQuotation.frete.tipo === "1",
                shipping_cost: this.answerQuotation.frete.tipo === "1" || isEmpty(this.answerQuotation.frete.valor) || this.answerQuotation.frete.valor === 0 ? null : this.answerQuotation.frete.valor.round(2),
                buyer_responsability: this.answerQuotation.frete.tipo === "1" || isEmpty(this.answerQuotation.frete.conta_comprador) ? false : this.answerQuotation.frete.conta_comprador,
                deliver: this.answerQuotation.frete.tipo === "1" || isEmpty(this.answerQuotation.frete.nao_entrega) ? true : !this.answerQuotation.frete.nao_entrega
            };

        if (isEmpty(this.condicoesFornecimento) || isEmpty(this.condicoesFornecimento.fretes))
            return "";

        let total = this.sellerService.obterTotal(this.answerQuotation);
        let frete = this.condicoesFornecimento.fretes[0];

        let gratuito = total >= frete.min_value || frete.shipping_cost === 0;
        let buyer_responsability = frete.buyer_payer;

        return {
            frete_gratuito: gratuito,
            shipping_cost: (!gratuito && !buyer_responsability) ? frete.shipping_cost : null,
            buyer_responsability: buyer_responsability,
            deliver: true
        };
    }

    get freteSelecionadoTexto(): any {
        if (isEmpty(this.condicoesFornecimento) || !this.condicoesFornecimento.done_configuration || isEmpty(this.condicoesFornecimento.fretes))
            return "";

        let total = this.sellerService.obterTotal(this.answerQuotation);
        let frete = this.condicoesFornecimento.fretes[0];

        if (frete.buyer_payer)
            return this.globals.translate('seller.dashboard.responder.frete.conta-comprador');
        else if (total >= frete.min_value || frete.shipping_cost === 0)
            return this.globals.translate('seller.dashboard.responder.frete.gratuito');

        return formatCurrency(frete.shipping_cost, 'pt-BR', 'R$', 'BRL');
    }

    get tiposPagamento(): any[] {
        if (isEmpty(this.condicoesFornecimento) || !this.condicoesFornecimento.done_configuration || isEmpty(this.condicoesFornecimento.formasPagamento))
            return [];

        let total = this.sellerService.obterTotal(this.answerQuotation);

        return this.condicoesFornecimento.formasPagamento.filter(fp => fp.start_value <= total);
    }

    get tiposPagamentoMaiorQueAVista() {
        let lista_tipos = this.tiposPagamento;

        return (lista_tipos.length > 1 || ((lista_tipos.length === 1) && (lista_tipos[0].payment_type_id > 0)));
    }
}
