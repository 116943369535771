<div class="condicao-fornecimento">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1" [ngClass]="active === 1 ? 'active' : ''">
            <a ngbNavLink>{{ 'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.abas.forma-pagamento' | translate}}</a>
            <ng-template ngbNavContent>

                @if (formas?.length <= 0) {
                    <div class="vazio">
                        {{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.forma-pagamento.vazio' | translate}}
                    </div>
                } @else {
                    <section class="d-flex flex-wrap striped">
                        <header>
                            <div class="linha">
                                <div class="col">{{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.forma-pagamento.colunas.forma' | translate}}</div>
                                <div class="col">{{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.forma-pagamento.colunas.valor-de' | translate}}</div>
                            </div>
                        </header>
                        <div class="corpo">
                            <ng-scrollbar>
                                @for (forma of formas; track forma) {
                                    <div class="linha">
                                        <div class="col truncate">{{ forma.paymentType.texto_x}}</div>
                                        <div class="col">{{ forma.start_value | currency:'R$ ' }}</div>
                                    </div>
                                }
                            </ng-scrollbar>
                        </div>
                    </section>
                }

            </ng-template>
        </li>
        <li [ngbNavItem]="2" [ngClass]="active === 2 ? 'active' : ''">
            <a ngbNavLink>{{ 'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.abas.frete' | translate}}</a>
            <ng-template ngbNavContent>

                @if (fretes?.length <= 0) {
                    <div class="vazio">
                        {{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.frete.vazio' | translate}}
                    </div>
                } @else {
                    <section class="d-flex flex-wrap striped">
                        <header>
                            <div class="linha">
                                <div class="col">{{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.frete.colunas.regiao' | translate}}</div>
                                <div class="col">{{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.frete.colunas.conta-comprador' | translate}}</div>
                                <div class="col">{{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.frete.colunas.valor-frete' | translate}}</div>
                                <div class="col">{{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.frete.colunas.valor-minimo-gratis' | translate}}</div>
                            </div>
                        </header>
                        <div class="corpo">
                            <ng-scrollbar>
                                @for (frete of fretes; track frete) {
                                    <div class="linha">
                                        <div class="col truncate">{{ frete.region_name }}</div>
                                        <div class="col">{{ (frete.buyer_payer ? 'elemento.generico-select.sim' : 'elemento.generico-select.nao') | translate }}</div>
                                        <div class="col">{{ frete.shipping_cost | currency:'R$ ' }}</div>
                                        <div class="col">{{ frete.min_value | currency:'R$ ' }}</div>
                                    </div>
                                }
                            </ng-scrollbar>
                        </div>
                    </section>
                }

            </ng-template>
        </li>
        <li [ngbNavItem]="3" [ngClass]="active === 3 ? 'active' : ''">
            <a ngbNavLink>{{ 'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.abas.prazo-entrega' | translate}}</a>
            <ng-template ngbNavContent>

                @if (prazos?.length <= 0) {
                    <div class="vazio">
                        {{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.prazo-entrega.vazio' | translate}}
                    </div>
                } @else {
                    <section class="d-flex flex-wrap striped">
                        <header>
                            <div class="linha">
                                <div class="col">{{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.prazo-entrega.colunas.regiao' | translate}}</div>
                                <div class="col">{{'seller.dashboard.responder.condicoes-fornecimento.modal.condicoes.prazo-entrega.colunas.prazo' | translate}}</div>
                            </div>
                        </header>
                        <div class="corpo">
                            <ng-scrollbar>
                                @for (prazo of prazos; track prazo) {
                                    <div class="linha">
                                        <div class="col truncate">{{ prazo.region_name }}</div>
                                        <div class="col">{{ prazo.delivery_time_name }}</div>
                                    </div>
                                }
                            </ng-scrollbar>
                        </div>
                    </section>
                }

            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>
