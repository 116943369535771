@if (fotos?.length > 0) {
    <div class="row col-md-12 no-print pr0" style="margin-bottom: 20px;">
        <div class="col-md-12 fotos pr0">
            <div class="card card-header painelcabecalho mb0" (click)="exibir_fotos = !exibir_fotos" data-bs-toggle="collapse" href="#collapseOne" [aria-expanded]="exibir_fotos" aria-controls="collapseOne">
                <h4 class="card-title">
                    <i class="fa-picture-o fa"></i> &nbsp;
                    <a>
                        {{ (exibir_fotos ? 'seller.pedidos.fotos.esconder' : 'seller.pedidos.fotos.ver-mais') | translate }}
                    </a>
                </h4>
            </div>
            <div id="collapseOne" class="collapse-wrapper collapse {{exibir_fotos ? 'show' : ''}}">
                <div class="col-md-8 offset-md-2 carousel-card-body">
                    @if (fotos) {
                        <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true">
                            @for (image of fotos; track image) {
                                <ng-template ngbSlide>
                                    <div class="picsum-img-wrapper">
                                        <img class="image-to-zoom" [src]="image" alt="Foto Veículo" (click)="zoomImage(image)">
                                    </div>
                                </ng-template>
                            }
                        </ngb-carousel>
                    }
                </div>
            </div>
        </div>
    </div>
}
