import { Component, OnInit } from '@angular/core';
import { getScreenSizeIsMobile } from 'app/_utils/general-utils';

@Component({ selector: 'app-responder', templateUrl: './responder.component.html', styleUrls: ['./responder.component.scss'] })

export class SellerVisaoGeralResponderComponent implements OnInit
{
    menor1024: boolean;

    constructor() { }

    ngOnInit()
    {
        this.menor1024 = getScreenSizeIsMobile();
    }
}
